import requst from '../utils/request'
//协议列表
export function treatyList() {
    return requst({
        url: '/admin/base/treatyList',
        method: 'get'
    })
}
//添加协议
export function treatyAdd(data) {
    return requst({
        url: '/admin/base/treatyAdd',
        method: 'post',
        data: data
    })
}
//删除协议
export function treatyDel(data) {
    return requst({
        url: '/admin/base/treatyDel',
        method: 'delete',
        data: data
    })
}
//协议分类
export function treatyType() {
    return requst({
        url: '/admin/base/treatyType',
        method: 'get'
    })
}

//编辑协议
export function treatyEdit(data) {
    return requst({
        url: '/admin/base/treatyUpdate',
        method: 'post',
        data: data
    })
}
