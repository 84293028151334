<template>
  <div>
    <el-card>
      <div class="title header">
        <div></div>
        <div>
          <el-button
            class="el-button el-button--primary"
            @click="add_agreement"
            slot="reference"
            >添加协议</el-button
          >
        </div>
      </div>
      <div>
        <el-table
          :data="list"
          stripe
          style="width: 100%"
          v-loading="loding"
          border
        >
          <el-table-column
            type="selection"
            width="55px"
            align="center"
          ></el-table-column>
          <el-table-column prop="id" label="ID" align="center" width="60px">
          </el-table-column>
          <el-table-column
            prop="title"
            label="标题"
            align="center"
            width="300px"
          >
          </el-table-column>

          <el-table-column label="协议类型" align="center" prop="mode_name">
          </el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <div>
                {{
                  $moment(scope.row.create_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" align="center">
            <template slot-scope="scope">
              <div>
                {{
                  $moment(scope.row.update_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="toEditAgree(scope.row)"
                size="small"
              ></el-button>
              <el-tooltip content="删除协议" placement="bottom" effect="dark">
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="deltreaty(scope.row.id)"
                  size="small"
                  circle
                ></el-button>
              </el-tooltip>
              <el-button
                type="success"
                size="small"
                icon="el-icon-info"
                @click="toViewDetails(scope.row)"
                circle
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 详情弹框 -->
      <el-dialog title="详情" :visible.sync="detailsDialog" width="50%">
        <el-form :model="detailsData">
          <el-form-item label="ID：" label-width="100px">
            {{ detailsData.id }}
          </el-form-item>

          <el-form-item label="协议标题：" label-width="100px">
            {{ detailsData.title }}
          </el-form-item>
          <el-form-item label="协议类型：" label-width="100px">
            {{ detailsData.mode_name }}
          </el-form-item>
          <el-form-item label="创建时间：" label-width="100px">
            {{
              $moment(detailsData.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>
          <el-form-item label="更新时间：" label-width="100px">
            {{
              $moment(detailsData.update_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </el-form-item>

          <el-form-item label="协议内容：" label-width="100px">
            <quill-editor
              v-model="detailsData.content"
              ref="myQuillEditor"
              @focus="onEditorFocus($event)"
            >
            </quill-editor>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 添加、编辑弹窗 -->
      <el-dialog
        :title="dialog_title"
        :visible.sync="dialogVisible"
        width="70%"
        :close-on-click-modal="false"
        @close="closed"
      >
        <div>
          <div class="row">
            <div>协议标题：</div>
            <div><el-input v-model="postdata.title"></el-input></div>
          </div>
          <div class="row">
            <div>协议类型：</div>
            <div>
              <el-select v-model="postdata.mode" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="content_last_div row">
            <div>
              <div>协议内容：</div>
              <div>
                <quill-editor
                  v-model="postdata.content"
                  ref="myQuillEditor"
                  style="width: 100%;height:400px;"
                >
                </quill-editor>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer footer_dialog">
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </div>
          <div>
            <el-button type="primary" @click="save">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
  treatyList,
  treatyAdd,
  treatyDel,
  treatyType,
  treatyEdit
} from "../../api/agreements";

export default {
  components: {
    quillEditor
  },
  data: function() {
    return {
      loding: false,
      list: [],
      dialogVisible: false,
      dialog_title: "",
      editorOption: {},
      postdata: {
        mode: "",
        title: "",
        content: ""
      },
      options: [],
      btn_type: "add",
      detailsData: {}, //详情数据
      detailsDialog: false //详情弹框
    };
  },
  methods: {
    // 查看详情
    toViewDetails(row) {
      this.detailsData = row;
      this.detailsDialog = true;
    },

    onEditorFocus(event) {
      event.enable(false);
    },
    //获取协议列表
    gettreatyList() {
      treatyList().then(res => {
        this.list = res.data.data;
      });
    },
    //添加协议
    add_agreement() {
      this.dialogVisible = true;
      this.dialog_title = "添加协议";
      this.btn_type = "add";
      for (var item in this.postdata) {
        if (item == "id") {
          delete this.postdata[item];
        }
      }
    },
    //保存
    save() {
      if (this.postdata.title == "") {
        this.$message({
          type: "warning",
          message: "协议标题必填!"
        });
        return;
      }
      if (this.postdata.mode == "") {
        this.$message({
          type: "warning",
          message: "协议类型必选!"
        });
        return;
      }
      if (this.btn_type == "add") {
        treatyAdd(this.postdata).then(res => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: res.msg
            });

            this.dialogVisible = false;
            this.$message.success("添加协议成功");
            this.gettreatyList();
          }
        });
      }
      if (this.btn_type == "edit") {
        treatyEdit(this.postdata).then(res => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: res.msg
            });

            this.dialogVisible = false;
            this.$message.success("编辑协议成功");
            this.gettreatyList();
          }
        });
      }
    },
    // 编辑
    toEditAgree(row) {
      this.postdata = row;
      this.dialogVisible = true;
      this.dialog_title = "编辑协议";
      this.btn_type = "edit";
    },
    //删除
    deltreaty(id) {
      let that = this;
      this.$confirm("此操作将永久删除该协议, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(function() {
          treatyDel({ id: id }).then(res => {
            if (res.code == 1) {
              that.$message({
                type: "success",
                message: res.msg
              });
              that.dialogVisible = false;
              that.gettreatyList();
            }
          });
        })
        .catch(res => {});
    },
    // 获取协议类型
    getTreatyType() {
      treatyType().then(res => {
        this.options = res.data;
      });
    },
    // 弹窗关闭事件
    closed() {
      this.postdata = {
        mode: "",
        title: "",
        content: ""
      };
    }
  },
  created() {
    this.gettreatyList();
    this.getTreatyType();
  }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.el-tag {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
}
.row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.row div:first-child {
  width: 200px;
}
.content_last_div {
  margin-top: 10px;
}
.content_last_div div:first-child {
  margin-bottom: 10px;
  width: 100% !important;
}
.footer_dialog {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}
.footer_dialog div {
  margin-left: 10px;
}
.footer_dialog button {
  width: 120px;
}
</style>
